import React from "react";
import OwlCarousel from 'react-owl-carousel';
const owl_logo_Slider = {
  items: 10,
  loop: true,
  dots: false,
  margin: 20,
  autoplay: true,
  responsiveClass: true,
  fluidSpeed: true,
  slideTransition: 'linear',
  autoplayTimeout: 2000,
  autoplaySpeed: 3000,
  autoplayHoverPause: false,
  responsive: {
    0: {
      items: 1,
    },
    575: {
      items: 2,
    },
    767: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1024: {
      items: 4,
    },
    1199: {
      items: 5,
    }
  },
};

const slides = [
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/Benzinga_logo.svg", link: "https://www.benzinga.com/pressreleases/23/09/ac34699939/the-2-0-conferences-announces-its-return-in-2024-with-crucial-industry-insights", alt: "Benzinga Logo" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/Business_Insider.svg", link: "https://markets.businessinsider.com/news/stocks/the-2-0-conferences-announces-its-return-in-2024-with-crucial-industry-insights-1032638401", alt: "Business Insider Logo" },
  
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/yahoo_f.svg", link: "https://finance.yahoo.com/news/2-0-conferences-announces-return-181500094.html?guccounter=2&guce_referrer=aHR0cHM6Ly93d3cuaGVhbHRoMmNvbmYuY29tLw&guce_referrer_sig=AQAAAD7tAwAOIr8NkSOocGWwdoLtsHsRPs6kf_R5Bobv1cNkw2tQYAEwU8ma776boYFG5RC-JQYMSDoUjpQKLQTm8HWLQh2-2I_kMHhO1w1gvM6jK8bO_Du5zQcykPocEUDzBak79aSBNjx-61KEm24caVR3SyvovNZqsYKY_5kgZGeE", alt: "Yahoo Finance Logo" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/us-time.png", link: "https://www.ustimesnow.com/the-2-0-conferences-announces-its-return-in-2024-with-crucial-industry-insight/", alt: "US Times Logo" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/slide-logo.png", link: "https://www.streetinsider.com/Accesswire/The+2.0+Conferences+Announces+Its+Return+In+2024+With+Crucial+Industry+Insights/22171831.html", alt: "Street Insider Logo" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/strip-logo/khaleejtimes.svg", link: "https://www.khaleejtimes.com/kt-network/the-2-0-conferences-the-epicenter-of-next-gen-breakthroughs-in-dubai", alt: "Khaleej Times Logo" },
];

const HomeSlider = () => {
  return (
    <>
      <section className="home_slider strip_slider">
        <h2 className="text-center">AS FEATURED ON</h2>
        <div className="container">
          <OwlCarousel
            className="owl-theme home_slider_carousel "
            {...owl_logo_Slider}
          >
            {slides.map((slide, index) => (
              <div className="item" key={index}>
                <a href={slide.link} target="_blank" rel="noopener noreferrer">
                  <img src={slide.src} alt={slide.alt} />
                </a>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default HomeSlider;
